<template>
  <el-container>
    <el-aside width="600px">
      <el-container>
        <el-header height="40px">
          <el-form :inline="true">
            <el-form-item label="档口名称：">
              <el-input v-model="queryParam.SearchValue" placeholder="请输入档口名称" style="width: 147px"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button type="primary" @click="handleQuery()">查询</el-button>
                <el-button type="default" @click="reset()">重置</el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item style="float:right;">
              <el-button type="primary" icon="el-icon-plus" @click="handleStoreAdd">新增</el-button>
              <el-button type="primary" icon="el-icon-delete" @click="handleStoreDelete(null)">删除</el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main>
          <grid-table ref="gridTable" :showIndexColumn="false" :showLastEmptyColumn="false" :showPagination="true"
            :tableConfig="tableConfig" @selectionChange="handleSelectionChange" @handleRowClick="handleRowClick"
            :resizable="false">
            <template slot="DefaultFlag" slot-scope="{ params }">
              <el-switch v-model="params.row.DefaultFlag" disabled active-color="#13ce66" ></el-switch>
            </template>
            <template slot="Name" slot-scope="{ params }">
              {{ params.row['Name'] }}
            </template>
            <template slot="OP" slot-scope="{ params }">
              <el-button type="text" @click.stop="handleStoreEdit(params.row)">编辑</el-button>
              <el-button type="text" @click.stop="handleStoreDelete(params.row)">删除</el-button>
            </template>
          </grid-table>
        </el-main>
      </el-container>
    </el-aside>
    <el-main>
      <el-container>
        <el-header height="40px" style="padding-top: 2px;">
          <span class="span-name">
            档口名称：{{ detail.row.Name == '' ? '默认档口' : detail.row.Name }}
          </span>
          <el-button type="text" icon="el-icon-download" @click="handleDownZip" class="btn_download">下载插件</el-button>
        </el-header>
        <el-table stripe border ref="table" row-key="Id" :data="detail.rowDetails" v-loading="detail.loading"
          :header-cell-style="{ background: '#eef1f6', color: '#606266' }" height="100%">
          <el-table-column label="类型" align="center" header-align="center">
            <template slot-scope="scope">{{ scope.row.BotType == 0 ? "QQ" : scope.row.BotType == 1 ? "微信" : "QQNT" }}</template>
          </el-table-column>
          <el-table-column prop="BotUid" label="账号" />
          <el-table-column prop="BotName" label="昵称" />
          <el-table-column prop="WName" label="发货仓库" v-if="$store.state.routes.systemSetting.MultiWarehouse">
            <template slot-scope="scope">{{ scope.row.WName != '' ? scope.row.WName : "默认仓库" }}</template>
          </el-table-column>
          <!-- <el-table-column prop="BotUrl" label="插件Url" /> -->
          <el-table-column prop="CreateTime" label="关联时间" />
          <el-table-column label="操作" width="210" align="center" header-align="center">
            <template slot-scope="scope">
              <el-button plain size="mini" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button plain size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-footer height="40px">
          <el-button type="primary" plain icon="el-icon-plus" @click="handleAddNew" :disabled="detail.row.ID <= 0"
            class="btn_add_detail">新增QQ</el-button>
        </el-footer>
      </el-container>
    </el-main>
    <stall-edit-account ref="stallEditAccount" v-if="editData.visible" @handleSearch="handleSearch" />
    <el-dialog width="600px" :title="storeDetailDialog.title" :visible="storeDetailDialog.visible"
      @close="storeDetailDialog.visible = false" :close-on-click-modal="false">
      <el-form label-width="100px">
        <el-form-item label="档口名称：" prop="Name">
          <el-input v-model="storeDetailDialog.form.Name" placeholder="请输入档口名称" />
        </el-form-item>
        <el-form-item label="详细地址：" prop="Address">
          <el-input v-model="storeDetailDialog.form.Address" type="textarea" :rows="5" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="storeDetailDialog.visible = false">取 消</el-button>
        <el-button type="primary" v-loading="storeDetailDialog.loading" @click="handleSaveStore">确 定</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import { submit } from "@/api/base";
import StallEditAccount from "./components/Edit.vue";

export default {
  name: "StallBotAccount",
  components: {
    StallEditAccount,
  },
  data() {
    return {
      loading: false,
      formData: {},
      queryParam: {
        SearchValue: '',
        SearchField: 'Name',
      },
      tableConfig: {
        url: "/api/stallBotAccount/getStoreList",
        rowKey: "ID",
        columns: [
          {
            label: "名称",
            prop: "Name",
            type: "custom-operation",
            width: "180px",
          },
          {
            label: "地址",
            prop: "Address",
            type: "text",
          },
          {
            label: "默认档口",
            prop: "DefaultFlag",
            type: "custom-operation",
            width: "80px",
          },
          {
            label: "操作",
            prop: "OP",
            type: "custom-operation",
            width: "85px",
          },
        ],
      },
      storeDetailDialog: {
        title: '',
        form: {
          ID: 0,
          Name: '',
          Address: '',
        },
        loading: false,
        visible: false,
      },
      selectRows: [],
      detail: {
        loading: false,
        row: { ID: 0, Name: '' },
        rowDetails: [],
      },
      editData: {
        visible: false,
      },
    };
  },
  mounted() {
    this.$nextTick(async () => {
      await this.handleQuery();
      await this.reloadSystemSetting();
    });
  },
  methods: {
    async reloadSystemSetting() {
      var rsp = await submit("/api/stall/getSystemSetting");
      this.$store.state.routes.systemSetting = rsp.data;
    },
    removeEditData() {
      this.detail.row = { ID: 0, Name: '' };
      this.detail.rowDetails = [];
    },
    handleStoreAdd() {
      this.storeDetailDialog.title = '新增店铺';
      this.storeDetailDialog.form = {
        ID: 0,
        Name: '',
        Address: '',
      };
      this.storeDetailDialog.visible = true;
    },
    handleStoreDelete(row) {
      var _this = this;
      var idList = [];
      var confirmText = '';
      if (row == null) {
        if (!_this.selectRows || _this.selectRows.length == 0) {
          _this.$baseMessage(`请选择要删除的数据`, "error");
          return false;
        }
        _this.selectRows.forEach(t => {
          idList.push(t['ID']);
        });
        confirmText = '确定要删除选中的 ' + _this.selectRows.length + '个档口及其下的所有QQ？';
      } else {
        idList.push(row['ID']);
        confirmText = '确定要删除删除档口【' + row['Name'] + '】及其下所有QQ？';
      }
      _this.$confirm(confirmText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在提交中...';
            const rsp = await submit('/api/stallBotAccount/deleteStore', { IDs: idList }).catch((error) => {
              instance.confirmButtonLoading = false;
              instance.confirmButtonText = '确认';
              done();
            });
            instance.confirmButtonLoading = false;
            _this.$message({ type: rsp.success ? "success" : "error", message: rsp.msg });
            _this.handleQuery();
            _this.reloadSystemSetting();
            done();
          } else {
            done();
          }
        }
      });
    },
    handleStoreEdit(row) {
      this.storeDetailDialog.title = '编辑店铺';
      this.storeDetailDialog.form = { ...row };
      this.storeDetailDialog.visible = true;
    },
    async handleSaveStore() {
      var that = this;
      if (that.storeDetailDialog.form.Name == '') {
        that.$message({ type: 'error', message: '档口名称不能为空' });
        return;
      }
      that.storeDetailDialog.loading = true;
      let rsp = await submit("/api/stallBotAccount/saveStore", that.storeDetailDialog.form).catch((error) => {
        that.storeDetailDialog.loading = false;
      });
      that.storeDetailDialog.loading = false;
      if (!rsp.success) {
        that.$message({ type: 'error', message: rsp.msg });
      } else {
        that.$message({ type: "success", message: "操作成功!", });
        that.storeDetailDialog.visible = false;
        that.handleQuery(rsp.data.ID);
        that.reloadSystemSetting();
      }
    },
    reset() {
      this.queryParam.SearchValue = '';
      this.handleQuery();
    },
    async handleRowClick(row) {
      var that = this;
      that.$refs.gridTable.$refs.gridTable.clearSelection();
      that.$refs.gridTable.$refs.gridTable.toggleRowSelection(row);
      that.removeEditData();
      that.detail.loading = true;
      const { data } = await submit("/api/stallBotAccount/getList", {
        StoreID: row.ID,
      });
      that.detail.row = row;
      that.detail.rowDetails = data;
      that.detail.loading = false
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    async handleQuery(id) {
      var that = this;
      that.removeEditData();
      that.$refs.gridTable.searchParam = { ...that.queryParam };
      that.$refs.gridTable.fetchData(typeof (id) != 'undefined' && id > 0, function (tableData) {
        if (typeof (id) != 'undefined' && id > 0) {
          var rows = tableData.filter(x => x.ID == id);
          if (rows.length > 0) {
            that.$nextTick(() => {
              that.handleRowClick(rows[0]);
            });
          }
        }
      }, that.$refs.gridTable.searchParam);
    },
    async fetchData() {
      this.tableData.loading = true;
      const { data } = await submit("/api/stallBotAccount/getList", {
        PageSize: this.tableData.pageSize,
        CurrentPage: this.tableData.currentPage,
      }).finally(() => {
        this.tableData.loading = false;
      });
      this.tableData.items = data;
    },
    handleSearch() {
      if (this.detail.row.ID <= 0) {
        this.handleQuery();
      } else {
        this.handleRowClick(this.detail.row);
      }
    },
    handleSizeChange(size) {
      this.tableData.pageSize = size;
      this.fetchData();
    },
    handleCurrentChange(page) {
      this.tableData.currentPage = page;
      this.fetchData();
    },
    handleAddNew() {
      var that = this;
      that.editData.visible = true;
      that.$nextTick(() => {
        that.$refs.stallEditAccount.initDialog("新增账号", undefined, { StoreID: that.detail.row.ID });
      });
    },
    handleEdit(row) {
      this.editData.visible = true;
      this.$nextTick(() => {
        this.$refs.stallEditAccount.initDialog(`编辑账号 - ${row.BotUid}`, row.Id);
      });
    },
    handleDelete(row) {
      this.$confirm(`您是否要删除账号：${row.BotUid} ?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await submit(`/api/stallBotAccount/delete?id=${row.Id}`);
        if (result.success) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.handleSearch();
        }
      });
    },
    async handleDownZip() {
      var a = document.createElement("a");
      a.href = "/static/plugins/bot/QQ报单.zip";
      a.download = "QQ报单.zip";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .red {
    color: red;
  }

  .span-name {
    font-weight: bold;
    font-size: 20px;
  }

  .btn_download {
    float: right;
    margin-right: 10px;
  }

  .btn_add_detail {
    margin-top: 7px;
  }
}
</style>